import axios from 'axios';
import { styled } from '@mui/material/styles';
import { Fragment, useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import './CodeChecker.css';
import { Box } from '@mui/material';

const Root = styled('div')(() => ({}));

const CodeChecker = () => {
  const [code, setCode] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [userId, setUserId] = useState('');
  const [linkToProfile, setLinkToProfile] = useState('');
  const [userAvatarUrl, setUserAvatarUrl] = useState(null);
  const [loadingState, setLoadingState] = useState('init');

  const onChangeCode = (event) => {
    const value = event.target.value.replace(/(\s|[^A-Za-z0-9])/g, '').toUpperCase();
    setLoadingState('init');
    setCode(value);
    // console.log(value);
  };

  const onClickCheck = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    setLoadingState('request');
    const url = 'https://spg-ogs.api1.io/giveaway/check';
    // const url = 'http://127.0.0.1:3191/giveaway/check';
    const data = {
      // id: '20210306-01',
      // id: '20210417-02',
      // id: '20220312-03',
      // id: '20230121-01',
      id: '20230820-05',
      code,
    };
    const config = {
      headers: {
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTA0ODc5MjF9.Lun4MY1c4V6XQbEsK7m5vQ3k2foRk1wHFcR8C8rRt7o', // prod
        // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTQ3MDQzMzJ9.tV63D9D5zOSBruL8zxv-bz31h7eLBtakREsNmSYwb0s', // local
        // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzM1NTY5NTF9.AG_iRwpf1f0Qk6tl03mcRBROOxkMGka0vGj2SXsRjNw', // local
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.post(url, data, config);
      const { status, data: responseData } = response;
      if (status === 200) {
        setLoadingState('result');
        console.log(responseData);
        const { userId, displayName, avatarUrl } = responseData;
        setUserId(userId);
        setUserAvatarUrl(avatarUrl);
        setDisplayName(displayName);
      } else if (status === 404) {
        setLoadingState('not found');
      } else {
        setLoadingState('error');
      }
    } catch (err) {
      if (err.response && err.response.status && err.response.status === 404) {
        setLoadingState('not found');
      } else {
        setLoadingState('error');
      }
    }
  };

  useEffect(() => {
    setLinkToProfile('https://www.roblox.com/users/' + userId);
  }, [userId]);

  const isCodeEmpty = code === '';

  return (
    <Root sx={{ padding: '25px 0' }}>
      <form noValidate autoComplete="off" onSubmit={onClickCheck}>
        <Grid container spacing={'4'}>
          <Grid item md={4}>
            <FormGroup>
              <TextField id="giveaway-code" label="Code" value={code} onChange={onChangeCode} />
              <FormHelperText>{'Enter Code here / Введите здесь свой Код'}</FormHelperText>
            </FormGroup>
          </Grid>
          <Grid item md={8}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              sx={(theme) => ({
                margin: theme.spacing(0, 1, 0, 1),
                padding: theme.spacing(1.8, 1.5, 1.8, 1.5),
              })}
              onClick={onClickCheck}
              disabled={isCodeEmpty}
            >
              Check Code / Проверить Код
            </Button>
          </Grid>
        </Grid>
      </form>
      {loadingState === 'result' && userId && (
        <Fragment>
          <Alert elevation={6} variant="outlined">User is participating in Giveaway! / Пользователь участвует в розыгрыше!</Alert>
          <Card sx={{ maxWidth: '300px' }}>
            <CardMedia image={userAvatarUrl} title={`User: ${displayName}`} sx={{ margin: '0 auto', width: 200, height: 200 }} />
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography gutterBottom variant="h5" component="h2">{displayName}</Typography>
            </CardContent>
            <CardActions>
              <Box alignContent={'center'}>
                <Button variant="outlined" href={linkToProfile} target="_blank" rel="noreferrer">Open Profile</Button>
              </Box>
            </CardActions>
          </Card>
        </Fragment>
      )}
      {loadingState === 'not found' && (
        <Alert severity="info">Code was not found / Такой Код не найден</Alert>
      )}
      {loadingState === 'error' && (
        <Alert severity="error">Error / Ошибка</Alert>
      )}
    </Root>
  );
};

export default CodeChecker;
