import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

import CodeChecker from './components/CodeChecker';

import imgAvatar from './avatar-spg-200.jpg';
import imgBackground from './background2.jpg';
import './App.css';
import { Link, Typography } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';

const DivGrow = styled('div')({
  flexGrow: 1,
});

const LinkLogo = styled(Link)({
  lineHeight: 0,
});

const AvatarLogo = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  maxHeight: 80,
  animation: 'App-logo-spin infinite 20s linear',
}));

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(() => createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
    }}),
    [prefersDarkMode],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="static">
          <img src={imgBackground} alt="logo" />
          <Container maxWidth="lg">
            <Toolbar>
              <LinkLogo href="https://youtube.com/c/SofiaPlayingGames" target="_blank" rel="noopener noreferrer">
                <AvatarLogo src={imgAvatar} alt="logo" title="Official website Sofia Playing Games YouTube" />
              </LinkLogo>
              <Typography variant="h6" sx={{ margin: '10px' }}>Официальный сайт YouTube Канала: Sofia Playing Games</Typography>
              <DivGrow />
              <LinkLogo href="https://youtube.com/c/SofiaPlayingGames" target="_blank" rel="noopener noreferrer" title="Visit Sofia Playing Games YouTube" >
                <YouTubeIcon style={{ fill: "red" }} fontSize={'large'} />
              </LinkLogo>
            </Toolbar>
          </Container>
        </AppBar>
        <Container maxWidth="lg" sx={{ padding: '25px' }}>
          <Typography variant="h6">Check Robux Giveaway Code / Проверить Код на участие в Розыгрыше Робаксов</Typography>
          <CodeChecker />
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
